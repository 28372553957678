import React from "react";
import {
  hogkloppeData,
  niittyrantaData,
  niittyrantaImages,
  roorenData,
  salmenrantaData,
  vuorenRantaData,
  vuorenRantaImages,
} from "../data/data";
import EmptyImage from "./EmptyImage";
import Property from "./Property";

const Resume = () => {
  return (
    <section className="resume" id="kohteet">
      <div className="row work">
        <div className="three columns header-col" />

        <div className="nine columns main-col">
          <h2>Myytävät kohteet</h2>
        </div>
      </div>
      <Property
        data={vuorenRantaData}
        images={vuorenRantaImages}
        lightBox="vuoren-ranta"
        title="Vuorenranta"
        propertyName="Nuikontie 140 | Unelmiesi hirsihuvila merenrannalla"
        video={
          <iframe
            width="100%"
            height="360"
            style={{ borderRadius: "3%" }}
            src="https://www.youtube.com/embed/kLOupgK8hD8"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
          ></iframe>
        }
        contentFirstSection={
          <>
            Vuorenranta luksushuvila rakentuu Rymättylän upeaan saaristoon
            merenrantatontille vuoden 2023 aikana. Huvilan maisema avautuu
            lounaaseen merellisiin näköaloihin Nuikonlahdelle, nauttien
            auringonpaisteesta läpi vuoden. Tämä uniikki koti soveltuu
            täydellisesti ympärivuotiseen asumiseen sekä etätyöskentelyyn.
          </>
        }
        contentSecondSection={
          <p style={{ textAlign: "justify" }}>
            Vuorenrannan tontilla on 200m² rakennusoikeutta sekä yli 5000m²
            lohkomatonta määräalaa. Kohteen erikoisuutena on häikäisevät
            maisemat suurelta terassilta, johon ostajalla mahdollisuus upottaa
            esimerkiksi infinity pool tai jacuzzi toiveidensa mukaan. Sisältä
            asunto on avara, ja jokaisesta huoneesta avautuvat lattiasta kattoon
            olevat ikkunat vehreään luontoon. Olohuone ja keittiö löytyvät talon
            keskeltä, mahdollistaen seurustelemisen vieraiden kanssa läpi
            päivän. Talosta löytyy kaksi makuuhuonetta, jotka soveltuvat
            asukkaiden toiveiden mukaan myös kotitoimistoiksi. Päämakuuhuone on
            avara ja varustettu omalla kylpyhuoneella. Talosta löytyy myös kaksi
            erillistä wc-tilaa. Rakennuksen oikeaan päätyyn on sijoitettu
            tunnelmallinen näköalasauna tilavalla ja hyvin varustellulla
            kylpyhuoneella. Saunalta askeleet vievät yksityiselle
            hiekkarannalle, johon on mahdollista rakentaa erillinen rantasauna
            sekä betoniponttoonilaituri veneen kiinnitystä varten. Etupiha on
            varustettu kahdella autokatospaikalla latauspisteineen, jonka
            yhteydestä käynti myös yksityiseen varastoon.
            <br />
            <br />
            Kohde myydään avaimet käteen -periaatteella. Päärakennus ja
            autokatos valmistuvat kesäkuussa 2023. Rantasauna rakennetaan
            sopimuksen mukaan. Sovi aika näyttöön ja aloita rauhallisempi arki
            saaristossa.
            <br />
            <br />* 3D visualisointi:
            <a
              className="href-link"
              target="_blnk"
              rel="noreferrer"
              href="https://rendeko.fi/"
            >
              Rendeko
            </a>
          </p>
        }
      />
      <Property
        data={niittyrantaData}
        images={niittyrantaImages}
        lightBox="niitty-ranta"
        title="Niittyranta"
        propertyName="Nuikontie | Uniikki hirsitalo rantasaunalla"
        contentFirstSection={
          <>
            Hirsirunkoinen omakotitalo merenrannalla. Lisätietoja tulossa pian.
            Kohde ennakkomyynnissä. Kyselyt:
            <a href="mailto:sales@rimitoliving.fi">sales@rimitoliving.fi</a>
          </>
        }
      />
      <Property
        data={salmenrantaData}
        lightBox="salmen-ranta"
        title="Salmenranta"
        propertyName="Laitsalmentie 141 | Rakenna turvasatamasi merenrantatontille"
        contentFirstSection={
          <>
            Kohde ennakkomyynnissä. Kyselyt:
            <a href="mailto:sales@rimitoliving.fi">sales@rimitoliving.fi</a>
          </>
        }
        video={<EmptyImage />}
      />
      <Property
        data={hogkloppeData}
        video={<EmptyImage />}
        lightBox="högklobb"
        title="Högklobb"
        propertyName="Parainen | Ulkosaariston helmi"
        contentFirstSection={
          <>
            Uniikki yksityinen saari keskellä kauneinta Suomen ulkosaaristoa.
            Saaresta avautuu upeat näköalat eri ilmansuuntiin ja
            kristallinkirkas merivesi kutsuu uimaan kylmänäkin kesäpäivänä.
            Paikka, jossa voit rentoutua ja unohtaa arkiset kiireet. Saaressa on
            hirsirakenteinen vapaa-ajan asunto vuodelta 1970. Lisäksi
            päärakennuksen läheisyydessä on kompostoiva biokäymälä. Rannassa on
            betoniponttonilaituri sekä venevaja.
            <br />
            <br />
            Kohde ennakkomyynnissä. Kyselyt:
            <a href="mailto:sales@rimitoliving.fi">sales@rimitoliving.fi</a>
          </>
        }
      />
      <Property
        data={roorenData}
        lightBox="röören"
        title="Röören"
        propertyName="Parainen | Yksityinen saari ulkosaaristosta"
        video={<EmptyImage />}
        contentFirstSection={
          <>
            Yksityinen saari upealla paikalla saariston syleilyssä. Tämä paikka
            tarjoaa puitteet rentoutumiselle ja luonnosta nauttimiselle.
            Saaressa on hirsimökki vuodelta 1972, joka on kooltaan 28 m2.
            <br />
            <br />
            Kohde ennakkomyynnissä. Kyselyt:
            <a href="mailto:sales@rimitoliving.fi">sales@rimitoliving.fi</a>
          </>
        }
      />
    </section>
  );
};

export default Resume;
