import React, { Component } from "react";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <About />
        <Resume />
        <Footer />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ul>
            <h6>&copy; Copyright 2023 Rimito Living. All rights reserved.</h6>
          </ul>
        </div>
      </div>
    );
  }
}

export default App;
