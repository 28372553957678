const EmptyImage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        widh: "100%",
        height: "360px",
        backgroundColor: "rgba(0,0,0,0.2)",
        borderRadius: 3,
      }}
    >
      <h4 style={{ color: "white" }}>KUVIA TULOSSA PIAN</h4>
    </div>
  );
};

export default EmptyImage;
