import React, { Component } from "react";
import Fade from "react-reveal";
import Logo from "../Components/Logo.png";

class About extends Component {
  render() {
    return (
      <section id="meista">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={Logo}
                alt="Rimito living logo"
              />
            </div>
            <div className="nine columns main-col">
              <h2>
                Uniikkeja koteja ja vapaa-ajan asuntoja ainutlaatuisiin
                saaristomaisemiin
              </h2>

              <p style={{ textAlign: "justify" }}>
                Olemme rymättyläläinen talonrakennusyritys, joka on
                mahdollistanut asumisen unelmia aina vuodesta 2019. Rakennamme
                uniikkeja, laadukkaita ja ekologisia kohteita Turun seudun
                ainutlaatuisiin saaristomaisemiin. Tutustu myytävissä oleviin
                kohteisiimme ja varaa aika esittelylle.
              </p>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
