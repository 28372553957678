import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import { useState } from "react";
import { Slide } from "react-reveal";
import ImageGrid from "./ImageGrid";

const Property = ({
  title,
  data,
  images,
  lightBox,
  propertyName,
  contentFirstSection,
  contentSecondSection,
  video = false,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <Slide left duration={1300}>
      <div className="row work">
        <div className="three columns header-col">
          <h1>
            <span>{title}</span>
          </h1>
        </div>

        <div className="nine columns main-col" id="property-content">
          <div>
            <h5>{propertyName}</h5>
            <p className="property-p">
              {contentFirstSection}
              {!visible && contentSecondSection && (
                <span
                  style={{
                    width: 25,
                    cursor: "pointer",
                  }}
                  onClick={() => setVisible(!visible)}
                >
                  <ExpandMore />
                </span>
              )}
            </p>
            {visible && (
              <p style={{ textAlign: "justify" }}>
                {contentSecondSection}
                {visible && (
                  <p
                    style={{
                      width: 25,
                      cursor: "pointer",
                    }}
                    onClick={() => setVisible(!visible)}
                  >
                    <ExpandLess />
                  </p>
                )}
              </p>
            )}
          </div>
        </div>
        <div className="nine columns main-col">
          {video ? (
            video
          ) : (
            <img
              src={images?.[0]?.src}
              alt={images?.[0]?.alt}
              style={{ borderRadius: 3 }}
            />
          )}
          {!!images && (
            <StyledAccordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className="accordion-title">Kuvat</p>
              </AccordionSummary>
              <StyledAccordionDetails>
                <ImageGrid images={images} lightBox={lightBox} />
              </StyledAccordionDetails>
            </StyledAccordion>
          )}
          <StyledAccordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <p className="accordion-title">Lisätiedot</p>
            </AccordionSummary>
            <AccordionDetails>
              <StyledGrid container>
                {data.map((item) => {
                  if (item?.column) {
                    return (
                      <div key={item.title}>
                        <p className="row-text">{item.title}</p>
                        <p>{item.value}</p>
                      </div>
                    );
                  }
                  return (
                    <div key={`${item.title}`} className="row-item">
                      <p className="row-text">{item.title}</p>
                      <p className="row-content">{item.value}</p>
                    </div>
                  );
                })}
              </StyledGrid>
            </AccordionDetails>
          </StyledAccordion>
        </div>
      </div>
    </Slide>
  );
};

const StyledAccordion = withStyles({
  root: {
    borderRadius: 3,
    marginTop: 5,
    padding: "0 0px",
  },
})(Accordion);
const StyledAccordionDetails = withStyles({
  root: {
    padding: 0,
  },
})(AccordionDetails);
const StyledGrid = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
})(Grid);

export default Property;
