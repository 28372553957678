import Keittio from "../images/keittio.png";
import Makuuhuone from "../images/makuuhuone.png";
import Olohuone from "../images/olohuone.png";
import Sauna from "../images/sauna.png";
import Terassi from "../images/terassi.png";
import Vuorenranta from "../images/vuorenranta-talo.png";
import VuorenrantaParveke from "../images/vuorenranta/parveke.jpeg";
import VuorenrantaAutotalli from "../images/vuorenranta/vuorenranta-autotalli.jpeg";
import VuorenrantaMaisema from "../images/vuorenranta/vuorenranta-maisema.jpeg";
import VuorenrantaYolla from "../images/vuorenranta/vuorenranta-yo.jpeg";
import NiittyrantaHahmotus from "../images/niittyranta/niittyranta-hahmotus.jpg";
import NiittyrantaLuxSauna from "../images/niittyranta/niittyranta-lux-sauna.jpg";
import NiittyrantaPohja from "../images/niittyranta/niittyranta-pohja.jpg";
import NiittyrantaSaunaHahmotelma from "../images/niittyranta/niittyranta-saunahahmotelma.jpg";
import NiittyrantaSaunaPohja from "../images/niittyranta/niittyranta-saunapohja.jpg";
import NiittyrantaUlkoa from "../images/niittyranta/niittyranta-ulkoa.jpg";
import NiittyrantaUlkoa2 from "../images/niittyranta/niittyranta-ulkoa2.jpg";

const vuorenRantaData = [
  {
    title: "Sijainti",
    value: "Nuikontie 140, Rymättylä",
  },
  {
    title: "Rakennusala",
    value:
      "Omakotitalo 176m² + varasto / 2 paikan autokatos 9m² + rantasauna 15m²",
  },
  {
    title: "Valmistuu",
    value: "Kesällä 2023",
  },
  {
    title: "Talo suunnitelma",
    value: "Rimito living",
  },
  {
    title: "Hinta",
    value: "850 000€ - 1.2 milj.€",
  },
  {
    title: "Huoneiston kokoonpano",
    value: "2mh + olohuone + KT + S + 3wc + 2 kylpyhuone",
  },
  {
    title: "Rakennuksen tyyppi",
    value: "Omakotitalo",
  },

  {
    title: "Rakennusoikeus",
    value: "200m²",
  },
  {
    title: "Tontin koko",
    value: "13000m²",
  },
  {
    title: "Tontin omistus",
    value: "Yksityinen",
  },
  {
    title: "Rannan omistus",
    value: "Yksityinen",
  },
  {
    title: "Rantaviivaa",
    value: "n. 70 metriä. Mahdollisuus ostaa vesialuetta.",
  },
  {
    title: "Vesijohto & viemäri",
    value: "Vesiosuuskunta",
  },
  {
    title: "Sähköliittymä",
    value: "Caruna Oy, rakennettu ja liittymämaksut maksettu",
  },
];

const niittyrantaData = [
  {
    title: "Sijainti",
    value: "Nuikontie, 21140 Rymättylä",
  },
  {
    title: "Rakennusala",
    value: "Omakotitalo 145 m2 + rantasauna 25 m2",
  },
  {
    title: "Valmistuu",
    value: "Vuoden 2023 loppuun mennessä",
  },
  {
    title: "Talo suunnitelma",
    value: "Rimito living",
  },
  {
    title: "Hinta",
    value: "850 000€",
  },
  {
    title: "Huoneiston kokoonpano",
    value: "2mh + oh + kt + 2 wc + 2 kylpyhuonetta + kh + työhuone/vierashuone",
  },
  {
    title: "Rakennuksen tyyppi",
    value: "Omakotitalo",
  },

  {
    title: "Rakennusoikeus",
    value: "200m²",
  },
  {
    title: "Tontin koko",
    value:
      "8000m². Mahdollisuus ostaa vesialuetta tontin edestä noin hehtaarin verran, lisää alaa tonttiin 0,5 hehtaaria ja rantaviivaa 40 m",
  },
  {
    title: "Tontin omistus",
    value: "Yksityinen",
  },
  {
    title: "Rannan omistus",
    value: "Yksityinen",
  },
  {
    title: "Rantaviivaa",
    value: "n. 70 metriä",
  },
  {
    title: "Vesijohto & viemäri",
    value: "Vesiosuuskunta",
  },
  {
    title: "Sähköliittymä",
    value: "Caruna Oy, rakennettu ja liittymämaksut maksettu",
  },
];

const salmenrantaData = [
  {
    title: "Sijainti",
    value: "Laitsalmentie 141, 21150 Röölä",
  },
  {
    title: "Tontin koko",
    value: "5,3 hehtaaria",
  },
  {
    title: "Rakennusoikeus",
    value: "350 m2 + 30 m2 (60 m2)",
  },
  {
    title: "Tontin omistus",
    value: "Yksityinen",
  },
  {
    title: "Rantaviivaa",
    value: "n. 120 metriä. Ranta ruopattu, osittain myös hiekkanrataa",
  },
  {
    title: "Vesijohto & viemäri",
    value: "Tontilla viemäri, vesijohto ja sähköliittymä",
  },
  {
    title: "Hinta",
    value: "350 000€",
  },
  {
    title: "Lisätiedot",
    value:
      "Tällä hetkellä tontilla on purkukuntoisia rakennuksia, joiden purkutyöstä voidaan keskustella. Tontilla on tyydyttävän kuntoinen mökki rannassa ja tonttiin kuuluu rannan edessä oleva saari 1000 m2 sekä betoniponttonilaituri 10m x 3 m. Oikeus yhteisiin vesialueisiin.",
    column: true,
  },
];

const hogkloppeData = [
  {
    title: "Sijainti",
    value: "Högklobb",
  },
  {
    title: "Saaren pinta-ala",
    value: "47 000 m2",
  },
  {
    title: "Rannan omistus",
    value: "Yksityinen",
  },
  {
    title: "Asunnosa sauna",
    value: "Kyllä",
  },
  {
    title: "Saunan lisätiedot",
    value: "Puukiuas",
  },
  {
    title: "Rakennusoikeus",
    value: "280 m2",
  },
  {
    title: "Hinta",
    value: "300 000€",
  },
];

const roorenData = [
  {
    title: "Rakennusoikeus",
    value: "280 m2",
  },
  {
    title: "Tontin pinta-ala",
    value: "21 600 m2",
  },
  {
    title: "Asunnon pinta-ala",
    value: "28 m2",
  },
  {
    title: "Rannan omistus",
    value: "Yksityinen",
  },
  {
    title: "Asunnosa sauna",
    value: "Kyllä",
  },
  {
    title: "Saunan lisätiedot",
    value: "Puukiuas",
  },
  {
    title: "Hinta",
    value: "200 000€",
  },
];

const vuorenRantaImages = [
  {
    src: Vuorenranta,
    alt: "House in forest",
  },
  {
    src: Terassi,
    alt: "View to lake from forest",
  },
  {
    src: Makuuhuone,
    alt: "Bedroom with bed and view",
  },
  {
    src: Olohuone,
    alt: "Living room and open kitchen",
  },
  {
    src: Sauna,
    alt: "Sauna with a view to forest and lake",
  },
  {
    src: Keittio,
    alt: "Kitchen with view to forest",
  },
  {
    src: VuorenrantaParveke,
    alt: "Terrace with view to lake",
  },
  {
    src: VuorenrantaAutotalli,
    alt: "Garage and house",
  },
  {
    src: VuorenrantaMaisema,
    alt: "View to lake from forest",
  },
  {
    src: VuorenrantaYolla,
    alt: "Night view to lake from forest",
  },
];

const niittyrantaImages = [
  {
    src: NiittyrantaHahmotus,
    alt: "Front view of the house",
  },
  {
    src: NiittyrantaLuxSauna,
    alt: "Image of sauna",
  },
  {
    src: NiittyrantaUlkoa,
    alt: "Image of the house from outside",
  },
  {
    src: NiittyrantaUlkoa2,
    alt: "Image of the house from outside",
  },
  {
    src: NiittyrantaPohja,
    alt: "Image of the basement",
  },
  {
    src: NiittyrantaSaunaPohja,
    alt: "Layout of sauna",
  },
  {
    src: NiittyrantaSaunaHahmotelma,
    alt: "Sketch of a sauna",
  },
];

export {
  vuorenRantaData,
  vuorenRantaImages,
  niittyrantaData,
  salmenrantaData,
  roorenData,
  hogkloppeData,
  niittyrantaImages,
};
