import React, { Component } from "react"
import Fade from "react-reveal"
import Bg from "../images/vuorenranta-talo.png"

class Header extends Component {
  render() {
    return (
      <header id="koti" className="home">
        <div id="bg">
          <img src={Bg} alt="" />
        </div>
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#koti">
                Koti
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#meista">
                Meistä
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#kohteet">
                Kohteet
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#yhteystiedot">
                Ota yhteyttä
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <h1 className="responsive-headline">RIMITO LIVING</h1>
            </Fade>
            <hr />
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#meista">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    )
  }
}

export default Header
