import React, { Component } from "react"
import Fade from "react-reveal"

class Footer extends Component {
  render() {
    return (
      <section className="footer" id="yhteystiedot">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Fade bottom>
            <div className="footer-contact">
              <h3 style={{ color: "white" }}>Yhteystiedot</h3>
              <h6 className="footer-h6">
                Puh:{" "}
                <h6
                  style={{
                    fontWeight: "normal",
                    paddingLeft: 5,
                    color: "white",
                  }}
                >
                  +358 50 514 7494
                </h6>
              </h6>
              <h6 className="footer-h6">
                Osoite:{" "}
                <h6
                  style={{
                    fontWeight: "normal",
                    paddingLeft: 5,
                    color: "white",
                  }}
                >
                  Nuikontie 74, 21140 Rymättylä
                </h6>
              </h6>
              <h6 className="footer-h6">
                Sähköposti:
                <h6
                  style={{
                    fontWeight: "normal",
                    paddingLeft: 5,
                    color: "white",
                  }}
                >
                  <a href="mailto: sales@rimitoliving.fi">
                    sales@rimitoliving.fi
                  </a>
                </h6>
              </h6>
            </div>
          </Fade>

          <div className="social-media">
            <Fade bottom>
              <p style={{ color: "white" }}>
                Seuraa rakennusprojektejamme <br /> sosiaalisessa mediassa!
              </p>
              <div>
                <ul className="social-links">
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/rimitoliving"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/rimitoliving/"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Fade>

            <div id="go-top">
              <a className="smoothscroll" title="Takaisin ylös" href="#koti">
                <i className="icon-up-open"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Footer
