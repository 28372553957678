const ImageGrid = ({ images, lightBox }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridGap: "1rem",
        gridAutoRows: "minmax(100px, auto)",
      }}
    >
      {images.map((image, index) => {
        return (
          <a href={image.src} data-lightbox={lightBox} key={image.src}>
            <img
              src={image.src}
              alt={image.alt}
              style={{
                width: "100%",
                objectFit: "cover",
                aspectRatio: "16/9",
              }}
            />
          </a>
        );
      })}
    </div>
  );
};

export default ImageGrid;
